import React from 'react'
import { createRoot } from 'react-dom/client'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { captchaKey, gtmKey, sentryDsn } from '@constants/configuration'
import { isParamSet } from '@constants/utils'
import reportWebVitals from './reportWebVitals'
import { App } from './App'
import { initI18n } from './i18n'

// redirect to Estonian language by default
if (document.location.pathname === '/') {
  // eslint-disable-next-line functional/immutable-data
  document.location.pathname = '/et'
} else if (document.location.pathname.startsWith('/admin')) {
  // eslint-disable-next-line functional/immutable-data
  document.location.pathname = '/et/admin'
}

if (isParamSet(sentryDsn)) {
  // TODO: Switch to v8.X
  Sentry.init({
    dsn: sentryDsn,
    release: appVersion,
    environment: appMode,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

const consentScript = document.createElement('script')
// eslint-disable-next-line functional/immutable-data
consentScript.src = '/gtmCookies.js'
document.head.appendChild(consentScript)

const gtmHeadScript = document.createElement('script')
// eslint-disable-next-line functional/immutable-data
gtmHeadScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmKey}');`
// eslint-disable-next-line functional/immutable-data
gtmHeadScript.defer = true
document.head.appendChild(gtmHeadScript)

const gtmBodyScript = document.createElement('noscript')
// eslint-disable-next-line functional/immutable-data
const iframe = document.createElement('iframe')
// eslint-disable-next-line functional/immutable-data
iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmKey}`
// eslint-disable-next-line functional/immutable-data
iframe.height = '0'
// eslint-disable-next-line functional/immutable-data
iframe.width = '0'
// eslint-disable-next-line functional/immutable-data
iframe.style.display = 'none'
// eslint-disable-next-line functional/immutable-data
iframe.style.visibility = 'hidden'

gtmBodyScript.appendChild(iframe)
document.body.insertBefore(gtmBodyScript, document.body.firstChild)

const captchaScript = document.createElement('script')
// eslint-disable-next-line functional/immutable-data
captchaScript.src = `https://www.google.com/recaptcha/api.js?render=${captchaKey}`
// eslint-disable-next-line functional/immutable-data
captchaScript.defer = true
document.head.appendChild(captchaScript)

declare global {
  const grecaptcha: {
    ready: (callback: () => void) => void
    execute: (siteKey: string, options: { action: string }) => Promise<string>
  }

  const dataLayer: Array<Record<string, unknown>>
  const gtag: (...args: any[]) => void
  const fbq: (...args: any[]) => void
}

void initI18n().then(() => {
  createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})
